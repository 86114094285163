import product03 from "../assets/images/product04.jpg";
import product04 from "../assets/images/product06.jpg";
import product05 from "../assets/images/product02.jpg";
import cart from "../assets/images/cart.svg";
import "../assets/styles/ProductDetails.css";
import { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import renderHTMLContent from "../components/html";
import { API_URL } from '../configs';

const ProductDetails = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { lng } = useParams();
  const baseURL = `${API_URL}/api/v2/products/${id}`;
  // let id = (new URLSearchParams(window.location.search)).get("id");
  const [product, setProduct] = useState({});
  useEffect(() => {
    axios.get(baseURL,{
      headers: {
        locale: lng
      }}).then((response) => {
      setProduct(response.data);
    });
  }, []);
  // return {data};
  // console.log(jsonData);
  const [currentLang, setCurrentLang] = useState(false);
  useEffect(() => {
    if (i18next.language == "ar") {
      setCurrentLang(true);
    } else {
      setCurrentLang(false);
    }
  }, [t]);

  const [selectedSlide, setSelectedSlide] = useState(0);

  return (
    <div className="productDetails">
      <div className="productDetails_container">
        <div className="details_left">
          {product.images && product.images.map((image, index) => (
            <img key={index} src={image.url} alt={index}  className={`${index == selectedSlide ? "clicked" : "unClicked"}`} onClick={() => setSelectedSlide(index)}  />
          ))}
        </div>
        <div className="details_right">
          <h3 className="title">{lng === 'en' ? product.name_en : product.name_ar}</h3>
          {/* <p className="size">{jsonData.size}</p> */}
          <br />
          <p className="descp justify">

          <div dangerouslySetInnerHTML={{__html: lng === 'en' ? product.short_desc_en : product.short_desc_ar}} />

          </p>
        </div>
      </div>
      <div className="details_bottom">
        <div className="details_bottomLeft">
          <h3 className={`sec_title ${currentLang && "title_ar"}`} >{t("product_nutrition")}</h3>
          <table>
            <tbody>
              <tr>

              {product.nutrition && product.nutrition.map((item, index) => (
                    <td className="td">
                      <p className={`${item.style}`}>{lng === 'en' ? item.label_en : item.label_ar}</p>
                      <span>{lng === 'en' ? item.value_en : item.value_ar}</span>
                    </td>
                ))}
              </tr>
            </tbody>
          </table>
        </div>
        <div className="details_bottomRight justify">
        
          <p className="descp">
            {renderHTMLContent(lng === 'en' ? product.long_desc_en : product.long_desc_ar)}
          </p>
          <br />
        </div>
      
      </div>
    </div>
  );
};

export default ProductDetails;
