import HeroSlider from "../components/HeroSlider";
import "../assets/styles/Catalog.css";
import {catalogData} from "../fakeData/catalogData";
import {Link} from "react-router-dom";

const Catalog = () => {
  return (
    <>
    
      <div className="catalog">
        <h3 className="title">Catalogue</h3>
        <div className="catalog_container">
          {catalogData.map((catalog) =>(
            <Link to={"/" + 'en' + `/catalog/`+ catalog.id} key={catalog.id}>
              <img src={catalog.image} alt="" />
              <div className="recipe_name productList_cardName center">{catalog.title}</div>
             </Link>
          ))}
         
        </div>
      </div>
    </>
  );
};

export default Catalog;
