import HeroSlider from "../components/HeroSlider";
import "../assets/styles/About.css";

const Contact = () => {
  return (
    <>
      <HeroSlider
        slider1="https://v1-mazola.s3.ap-south-1.amazonaws.com/uploads/slider/Mazola+Product+1980x800-01-min.jpeg"
        slider2="https://v1-mazola.s3.ap-south-1.amazonaws.com/uploads/slider/Mazola+Product+1980x800-02-min.jpeg"
        slider3="https://v1-mazola.s3.ap-south-1.amazonaws.com/uploads/slider/slide-2.jpg"
      />
      <div className="about">
        <h2 className="title">Contact US</h2>
        <div className="about_container">
          <div className="about_left">
            <img src="https://v1-mazola.s3.ap-south-1.amazonaws.com/uploads/slider/Mazola-Contact-Us.jpg" alt="" />
          </div>
          <div className="about_right">
            <h4 className="sec_title">Head office Basateen Foods S.A Ltd</h4>
            <div className="contact_details">
              <img src="../images/phone.svg" className="contact_left" alt="" />
              <p className="contact_right descp">
                Tel: +966 126820969
                <br />
                Fax: +966 126981945
              </p>
            </div>
            <div className="contact_details">
              <img src="../images/mail.svg" className="contact_left" alt="" />
              <p className="contact_right descp">
                info@mazola.com.sa
                <br />
                support@mazola.com.sa
              </p>
            </div>
            <div className="contact_details">
              <img src="../images/map.svg" className="contact_left" alt="" />
              <p className="contact_right descp">
                P.O. Box : 53868
                <br />
                Jeddah 21593
                <br />
                Kingdom of Saudi Arabia
              </p>
            </div>
          </div>
        </div>
        <div className="about_container">
          <div className="about_left">
            <img src="https://v1-mazola.s3.ap-south-1.amazonaws.com/uploads/slider/Mazola-Contact-Us2.jpg" alt="" />
          </div>
          <div className="about_right">
            <h4 className="sec_title contact_title">Yanbu Plant</h4>
            <div className="contact_details">
              <img src="../images/phone.svg" className="contact_left" alt="" />
              <p className="contact_right descp">
              Tel: +966 143213123
                <br />
                Fax : +966 143212935
              </p>
            </div>
            <div className="contact_details">
              <img src="../images/mail.svg" className="contact_left" alt="" />
              <p className="contact_right descp">
                info@mazola.com.sa
                <br />
                support@mazola.com.sa
              </p>
            </div>
            <div className="contact_details">
              <img src="../images/map.svg" className="contact_left" alt="" />
              <p className="contact_right descp">
              P.O Box : 30429
                <br />
                Yanbu Al-Sinaiyah
                <br />
                Kingdom of Saudi Arabia
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
