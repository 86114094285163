import { useEffect } from "react";
import Slider from "react-slick";
// import slider1 from "../assets/images/slider1.png";
// import slider2 from "../assets/images/product03.jpg";
// import slider3 from "../assets/images/product05.jpg";
// import slider4 from "../assets/images/product02.jpg";
import "../assets/styles/HeroSlider.css";
const HeroSlider = ({ sliders }) => {
  useEffect(() => {
    // console.log("shown sliders: ", sliders)
  }, [])
  
  const settings = {
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    cssEase: "linear",
    appendDots: (dots) => (
      <div>
        <div
          style={{
            maxWidth: "110px",
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: "-80px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
            position: "relative",
          }}
        >
          {" "}
          {dots}{" "}
        </div>
      </div>
    ),
    customPaging: (i) => (
      <div
        style={{
          width: "30px",
          height: "30px",
          border: "2px solid #fff",
          borderRadius: "50%",
          // padding: "3px",
          marginTop: "-5px",
          marginLeft: "-5px",
        }}
      >
        <span></span>
      </div>
    ),
  };
  return (
    <div className="slider">
      
      <Slider {...settings}>
        {sliders.map((slider, key) => (
          <div key={key}>
            <img src={slider.featured_image} alt="slider" />
          </div>
        ))}
      </Slider>
      
    </div>
  );
};

export default HeroSlider;
