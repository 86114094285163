import share from "../assets/images/share.svg";
import facebook from "../assets/images/facebook.svg";
import twitter from "../assets/images/twitter.svg";
import instagram2 from "../assets/images/instagram2.svg";
import youTube from "../assets/images/youTube.svg";
import pinterest from "../assets/images/pinterest.svg";
import linkedIn from "../assets/images/linkedIn.svg";
import { Link } from "react-router-dom";
import "../assets/styles/Footer.css";
import { useParams } from 'react-router-dom';
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import Logo from "../assets/images/logo.svg";

const Footer = () => {
  const { t } = useTranslation();
  const locale = i18next.language ? i18next.language : "en" ;
  return (
    <>
      <div className="footer_top">
        <div className="footer_topContainer">
          <div className="footer_border"></div>
          <ul>
            <li className="first_list">{t("nav_mazola")}</li>

            <Link to={"/" + locale + "/products"}>
              <li>{t("nav_products")}</li>
            </Link>
            <Link to={"/" + locale + "/distributors"}>
              <li>{t("nav_distributors")}</li>
            </Link>
            {/* <Link to={"/" + locale + "/compare-products"}>
              <li>Compare</li>
            </Link> */}
            <Link to={"/" + locale + "/products"}>
              <li>{t("nav_categories")}</li>
            </Link>
            <li>
              <img className="logofooter" src="https://v1-mazola.s3.ap-south-1.amazonaws.com/uploads/logo/logo-1.png" alt="" />
            </li>
          </ul>
          <ul>
            <li className="first_list">{t("nav_online")}</li>
            <li>{t("nav_news_signup")}</li>
            <li><a href="https://docs.google.com/forms/d/e/1FAIpQLSe1tx5-ah9-nDAnysRMW-yFBpIMQMb18_YxBY7rfCIR4xw2mg/viewform">{t("nav_feedback")}</a></li>
          </ul>
          <ul>
            <li className="first_list">{t("nav_cook_mazola")}</li>
            <Link to={"/" + locale + "/recipes"}>
              <li>{t("nav_recipes")}</li>
            </Link>
            <Link to={"/" + locale + "/influencers"}>
              <li>{t("nav_influencers")}</li>
            </Link>
            <Link to={"/" + locale + "/cooking-class"}>
              <li>{t("nav_cooking_class")}</li>
            </Link>
          </ul>
          <ul className="last_ul">
            <li className="first_list">{t("nav_mazola_company")}</li>
            {/* <Link to="/news">
              <li>Latest News</li>
            </Link> */}
            <Link to={"/" + locale + "/catalog"}>
              <li>{t("nav_catalog")}</li>
            </Link>
            <li>{t("nav_jobs")}</li>
            <Link to={"/" + locale + "/contact"}>
              <li>{t("nav_contact")}</li>
            </Link>
            <li className="btn btn_black btn_share">
              <img src={share} alt="Share Page" /> <span>{t("nav_share")}</span>
            </li>
            <li className="footer_social">
              <a href="https://www.facebook.com/mazola.arabia/">
                <img src={facebook} alt="Facebook" />
              </a>
              <a href="https://twitter.com/mazolaarabia">
                <img src={twitter} alt="Twitter" />
              </a>
              <a href="https://www.instagram.com/mazolaarabia/">
                <img src={instagram2} alt="Instagram" />
              </a>
              <a href="https://www.linkedin.com/company/rcith/">
                <img src={linkedIn} alt="LinkedIn" />
              </a>
              <a href="https://www.youtube.com/channel/UCPF81_UdBtBYSEPTb4fzFsw">
                <img src={youTube} alt="YouTube" />
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="footer_bottom">
        <div className="footer_bottomContainer">
          <p className="footer_copyright">
            © {new Date().getFullYear()} {t("nav_copyright")}
          </p>
        </div>
      </div>
    </>
  );
};

export default Footer;
