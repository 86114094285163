import Card from "../components/Card";
import HeroSlider from "../components/HeroSlider";
// import { recipeDate } from "../fakeData/recipeDate";
import { useParams } from 'react-router-dom';
import axios from "axios";
import {useState, useEffect} from "react";
import { useTranslation } from "react-i18next";
import { API_URL } from '../configs';

const Cooking = () => {
  const { t } = useTranslation();
  const { lng } = useParams();
  const [isOpen, setOpen] = useState(false);
  const [cookings, setCookinngs] = useState([]);
  const [banners, setBanners] = useState();
  const baseURL = `${API_URL}/api/v2/cookings?filter={"active":[true]}`;

  useEffect(() => {
    axios.get(baseURL,{
      headers: {
        locale: lng
      }}).then((response) => {
      setCookinngs(response.data);
    });
  }, []);

  return (
    <>
      {banners && <HeroSlider sliders={banners} />}
      <div className="recipes">
      <h3 className="title">{t("cooking_title")}</h3>
        <p className="about_secHeading">
        {t("cooking_description")}
        </p>
        <div className="recipes_container">
          {cookings.map((cooking) => (
            <Card
                key={cooking.id}
                id={cooking.id}
                name={lng === 'en' ? cooking.name_en : cooking.name_ar}
                video_url={cooking.video_url}
                src={cooking.featured_image}
                video_modal = {true}
              />
            ))}
        </div>
      </div>
    </>
  );
};

export default Cooking;
