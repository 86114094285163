import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
} from "react-simple-maps";
import "../assets/styles/Distributors.css";
import { useState } from "react";

const geoUrl =
  "https://raw.githubusercontent.com/deldersveld/topojson/master/world-countries.json";
  // "https://raw.githubusercontent.com/vega/datalib/master/test/data/world-110m.json";
  // "../assets/styles/world-110m.json";

const markers = [
  {
    markerOffset: 8,
    name: "UAE",
    coordinates: [54.1193, 23.8],
    address1: "Al Seer Agencies & Trading",
    address2: "Shaikh Zayed road, Dubai",
    number1: "+97143725300",
    number2: "+97143473549",
    mail: "Info@alseer.com",
    site: "www.alseer.com",
    image: "https://mazola-prod.s3.me-south-1.amazonaws.com/uploads/distributor/UAE new 1.jpeg"
  },
  {
    markerOffset: 8,
    name: "Oman",
    coordinates: [57, 22],
    address1: "Al Hubaishi for Import and Foods Marketing",
    address2: "Shaikh Zayed road, Dubai",
    number1: "+97143725300",
    number2: "+97143473549",
    mail: "Info@alseer.com",
    site: "www.alseer.com",
    image: "https://mazola-prod.s3.me-south-1.amazonaws.com/uploads/distributor/oman new .jpeg"
  },
  {
    markerOffset: 8,
    name: "Qatar",
    coordinates: [51.4, 25],
    address1: "Al Yousuf Trading & Contracting Est.",
    address2: "101 Souq Waqif Area Zone-1, DOHA",
    number1: "+974 44422276",
    number2: "+974 44442911",
    mail: "mdarwish@alyousuf.qa",
    site: "",
    image: "https://mazola-prod.s3.me-south-1.amazonaws.com/uploads/distributor/qatar new .jpeg"
  },
  {
    markerOffset: 8,
    name: "Bahrain",
    coordinates: [50, 26.5],
    address1: "Al Seer Agencies  & Trading",
    address2: "Shaikh Zayed road, Dubai",
    number1: "+97143725300",
    number2: "+97143473549",
    mail: "Info@alseer.com",
    site: "www.alseer.com",
    image: "https://mazola-prod.s3.me-south-1.amazonaws.com/uploads/distributor/Bahrain-Manama-Skyline.jpeg"
  },
  {
    markerOffset: 8,
    name: "Kuwait",
    coordinates: [47.4, 29.6],
    address1: "Al Seer Agencies & Trading",
    address2: "Shaikh Zayed road, Dubai",
    number1: "+97143725300",
    number2: "+97143473549",
    mail: "Info@alseer.com",
    site: "www.alseer.com",
    image: "https://mazola-prod.s3.me-south-1.amazonaws.com/uploads/distributor/Kuwait-things-to-do-01.jpeg"
  },
  {
    markerOffset: 8,
    coordinates: [48, 16],
    name: "Yemen",
    address1: "Al Hubaishi for Import and Foods Marketing",
    address2: "Sanaa, Aden, Mukalla, Syioun, IBB and Hodeidah   ",
    number1: "+9675316590",
    number2: "+9675302066",
    mail: "alnor@yemen.net.ye",
    site: "www.hubaishifoods.com",
    image: "https://mazola-prod.s3.me-south-1.amazonaws.com/uploads/distributor/yamen.jpeg"
  },
  {
    markerOffset: 8,
    name: "Kingdom of Saudi Arabia",
    coordinates: [45.07,23.88],
    address1: "Head Office, P.O. Box 54",
    address2: "Jeddah 21411, Saudi Arabia",
    number1: "+966 - 12 - 2168000",
    number2: "+966 - 12 - 6475856",
    mail: "info@binzagr.com.sa",
    site: "www.binzagr.com.sa",
    image: "https://mazola-prod.s3.me-south-1.amazonaws.com/uploads/distributor/ksa.jpeg"
  },
  {
    markerOffset: 8,
    coordinates: [35.86,33.85],
    name: "Lebanon",
    address1: "United Foodstuffs and Catering Co SAL",
    address2: "Mount Lebanon, Mkalles (Metn), Building no. 38, Street no. 74, Industrial area",
    number1: "+961-1-683510/11 ",
    number2: "+961-1-683512",
    mail: "",
    site: "www.ufclb.com",
    image: "https://mazola-prod.s3.me-south-1.amazonaws.com/uploads/distributor/lebanon.jpeg"
  },
  {
    markerOffset: 8,
    coordinates: [36.23,30.58],
    name: "Jordan",
    address1: "George & Sami Khoury & Co.",
    address2: "Marka, Zanunia street, Amman, Jordan",
    number1: "+962 6 488 9989",
    number2: "+962 6 488 9979",
    mail: "gsk@gskhoury.com, support@gskhoury.com",
    site: "",
    image: "https://mazola-prod.s3.me-south-1.amazonaws.com/uploads/distributor/jordan.jpeg"
  },
  {
    markerOffset: 8,
    coordinates: [43.67,33.22],
    name: "Iraq",
    address1: "Alhaedb General Trading Co",
    address2: "Baghdad Road, Madain, Iraq. ",
    number1: "+9647901913134",
    number2: "+9647734963552",
    mail: "info@alhaedb.iq",
    site: "www.alhaedb.com",
    image: "https://mazola-prod.s3.me-south-1.amazonaws.com/uploads/distributor/iraq.jpeg"
  },
  {
    markerOffset: 8,
    coordinates: [26.5,50],
    name: "Palestine",
    address1: "AbuLaban General Trading Co",
    address2: "Palestine, Ramallah, Beitunia",
    number1: "+970 2 290 3121",
    number2: "",
    mail: "info@algt.ps",
    site: "www.algt.ps",
    image: "https://mazola-prod.s3.me-south-1.amazonaws.com/uploads/distributor/Bahrain-Manama-Skyline.jpeg"
  },
  {
    markerOffset: 8,
    coordinates: [27.16, 30.71],
    name: "Egypt",
    address1: "Ayman Afandey Co ",
    address2: "El Nahda St.  P.O Box. 473, Port Said -Egypt",
    number1: "012-23157780, 012-23991694, 011-19494990",
    number2: "+20 2 22927611",
    mail: "info@aac-asgeg.com",
    site: "www.aac-asgeg.com",
    image: "https://mazola-prod.s3.me-south-1.amazonaws.com/uploads/distributor/bigstock-egyptian-pyramids-in-sand-dese-265254931.jpeg"
  },
  {
    markerOffset: 8,
    coordinates: [17.22,26.33],
    name: "Libya",
    address1: "Al Kiram Group",
    address2: "Tripoli: Al Hachan area, Soq Al Ghomah",
    number1: "+218 61 223 9690 ",
    number2: "+218 61 222 4891",
    mail: "info@alkiramgroup.com",
    site: "www.alkiramgroup.com/shop/mazola/",
    image: "https://mazola-prod.s3.me-south-1.amazonaws.com/uploads/distributor/libya.jpeg"
  },
  {
    markerOffset: 8,
    coordinates: [30.21,12.86],
    name: "Sudan",
    address1: "Mizyan Trading and Services Co.Ltd",
    address2: "Buri Garden City No 9, Khartoum, Sudan",
    number1: "+249 912307943",
    number2: "+249 154889630, 31, 32",
    mail: "",
    site: "www.mizyan-sd.com",
    image: "https://mazola-prod.s3.me-south-1.amazonaws.com/uploads/distributor/Sudan new .jpeg"
  }
];

const Distributors = () => {
  const [show, setShow] = useState(false);
  const [slice, setSlice] = useState("0");

  return (
    <>
      <div className="map">
        <ComposableMap
          projection="geoAzimuthalEqualArea"
          projectionConfig={{
            rotate: [-45.0, -5, 0],
            scale: 500,
          }}
          style={{ background: "#0D274C" }}
        >
          <Geographies geography={geoUrl}>
            {({ geographies }) =>
              geographies.map((geo) => (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  fill="#E5E3DF"
                  stroke="#d1d0cf"
                />
              ))
            }
          </Geographies>
          {markers.map(({ name, coordinates, markerOffset }, index) => (
            <Marker
              key={name}
              coordinates={coordinates}
              onClick={() => setShow(true) || setSlice(index)}
            >
              <g
                fill="none"
                stroke="#FF5533"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                transform="translate(-12, -24)"
                style={{
                  cursor: "pointer",
                }}
              >
                <circle cx="12" cy="10" r="3" fill="#FF5533" />
                <path d="M12 21.7C17.3 17 20 13 20 10a8 8 0 1 0-16 0c0 3 2.7 6.9 8 11.7z" />
              </g>
              <text
                textAnchor="middle"
                y={markerOffset}
                style={{
                  fontFamily: "system-ui",
                  fill: "#000",
                  fontSize: "8px",
                  cursor: "pointer",
                }}
              >
                {name}
              </text>
            </Marker>
          ))}
        </ComposableMap>
      </div>
      {show &&
        markers.slice(0 + slice, 1 + slice).map((item, index) => (
          <div className="map_text" key={index}>
            <div className="map_left">
              <p className="sec_title">{item.name}</p>
              <p className="descp">{item.address1}</p>
              <p className="descp">{item.address2}</p>
              <p className="descp" style={{ fontWeight: 600 }}>
                {item.number1}
              </p>
              <p className="descp">{item.number2}</p>
              <p className="descp">{item.mail}</p>
              <p className="descp"><a href={"http://" + item.site}>{item.site}</a></p>
              </div>
            <div class="map_image"><img src={item.image}/></div>
          </div>
        ))}
    </>
  );
};

export default Distributors;
