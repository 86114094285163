import HeroSlider from "../components/HeroSlider";
import "../assets/styles/Home.css";
import Products from "../components/Products";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { useParams } from "react-router-dom";
import axios from "axios";
import product01 from "../assets/images/product01.jpg";
import product02 from "../assets/images/product02.jpg";
import product03 from "../assets/images/product03.jpg";
import product04 from "../assets/images/product04.jpg";
import product05 from "../assets/images/product05.jpg";
import product06 from "../assets/images/product06.jpg";

import instagram from "../assets/images/instagram.svg";

import RecipeSlider from "../components/RecipeSlider";
import { useEffect, useState } from "react";
import { API_URL } from '../configs';
import { Category } from "@material-ui/icons";

const Home = () => {
  const { lng } = useParams();
  const { t } = useTranslation();
  const [currentLang, setCurrentLang] = useState(false);
  const [banners, setBanners] = useState();
  const [categories, setCategories] = useState();
  const [recipes, setRecipes] = useState();
  const baseURL = `${API_URL}/api/v2/banners`;

  useEffect(() => {
    axios
      .get(`${API_URL}/api/v2/banners`, {
        headers: {
          locale: lng,
        },
      })
      .then((response) => {
        setBanners(response.data);
      });

      axios.get(`${API_URL}/api/v2/categories?filter={"is_featured":[true]}`,{
        headers: {
          locale: lng
        }}).then((response) => {
          // console.log("response", response)
        setCategories(response.data);
      });

      axios.get(`${API_URL}/api/v2/recipes?filter={"active":[true]}`,{
        headers: {
          locale: lng
        }}).then((response) => {
          console.log("response", response.data)
          setRecipes(response.data);
      });

    if (i18next.language == "ar") {
      setCurrentLang(true);
    } else {
      setCurrentLang(false);
    }
  }, []);

  // useEffect(() => {

  // }, []);
  
    return (
      <>
        {banners && (
          <HeroSlider sliders={banners} />
        )}
        <h2 className={`title ${currentLang && "title_ar"}`}>
          {t("home_products_title")}
        </h2>
        <div className="home_products">
          {categories && (categories.map((category) => (
              <Products src={category.featured_image} name={i18next.language === 'en' ? category.name_en : category.name_ar} />
          )))}
        </div>
        <h2 className={`title ${currentLang && "title_ar"}`}>
          {t("home_recipes_title")}
        </h2>
        <RecipeSlider sliders={recipes} />
        <h2 className="title">{t("home_social_title")}</h2>
        <div className="home_social">
          <div className="social_container">
            <div className="img1">
              <a href="https://www.instagram.com/p/CPLN0TSikDh/?utm_source=ig_web_copy_link">
                <img src="/images/s1.jpg" alt="" className="image" />
  
                <img src={instagram} alt="Instagram" className="instagram" />
              </a>
            </div>
            <div className="img2">
              <a href="https://www.instagram.com/p/CP-usX6teIj/?utm_source=ig_web_copy_link">
                <img src="/images/s2.JPG" alt="" />
  
                <img src={instagram} alt="Instagram" className="instagram" />
              </a>
            </div>
            <div className="img3">
              <a href="https://www.instagram.com/p/CQQwrkftWfA/?utm_source=ig_web_copy_link">
                <img src="/images/s3.JPG" alt="" />
  
                <img src={instagram} alt="Instagram" className="instagram" />
              </a>
            </div>
          </div>
          <div className="social_container2">
            <div className="img2">
              <a href="https://www.instagram.com/p/CP0ZV6rtsQt/?utm_source=ig_web_copy_link">
                <img src="/images/s4.JPG" alt="" />
  
                <img src={instagram} alt="Instagram" className="instagram" />
              </a>
            </div>
            <div className="img3">
              <a href="https://www.instagram.com/p/CQD0mHPiCWD/?utm_source=ig_web_copy_link">
                <img src="/images/s5.JPG" alt="" />
  
                <img src={instagram} alt="Instagram" className="instagram" />
              </a>
            </div>
            <div className="img1">
              <a href="https://www.instagram.com/p/CQQwrkftWfA/?utm_source=ig_web_copy_link">
                <img src="/images/s6.JPG" alt="" className="image" />
  
                <img src={instagram} alt="Instagram" className="instagram" />
              </a>
            </div>
          </div>
          <div className="social_container">
            <div className="img1">
              <a href="https://www.instagram.com/p/CSl1NRcoRCG/?utm_source=ig_web_copy_link">
                <img src="/images/s7.JPG" alt="" className="image" />
  
                <img src={instagram} alt="Instagram" className="instagram" />
              </a>
            </div>
            <div className="img2">
              <a href="https://www.instagram.com/p/CSl1NRcoRCG/?utm_source=ig_web_copy_link">
                <img src="/images/s8.JPG" alt="" />
  
                <img src={instagram} alt="Instagram" className="instagram" />
              </a>
            </div>
            <div className="img3">
              <a href="https://www.instagram.com/p/CT4cK71Ibf5/?utm_source=ig_web_copy_link">
                <img src="/images/s9.jpg" alt="" />
  
                <img src={instagram} alt="Instagram" className="instagram" />
              </a>
            </div>
          </div>
        </div>
        {/* <div className="social_btn">
          <button className="btn btn_black">Learn More</button>
        </div> */}
      </>
    );
  
};

export default Home;
