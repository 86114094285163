import Card from "../../components/Card";
import HeroSlider from "../../components/HeroSlider";
// import { recipeDate } from "../../fakeData/recipeDate";
import "../../assets/styles/RecipeDetails.css";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useState, useEffect } from "react";
import i18next from "i18next";
import { API_URL } from '../../configs';
import renderHTMLContent from "../../components/html";

const RecipeDetails = () => {

  let { id,lng } = useParams();
  const baseURL = `${API_URL}/api/v2/recipes/` + id;

  const { t } = useTranslation();
  const [recipe, setRecipe] = useState([]);
  useEffect(() => {
    axios.get(baseURL,{
      headers: {
        locale: lng
      }}).then((response) => {
        setRecipe(response.data);
      // console.log(response.data.data.recipe);
    });
  }, []);

  const apiURL = `${API_URL}/api/v2/recipes/`;
  const [recipeData, setRecipeData] = useState([]);
  useEffect(() => {
    axios.get(apiURL,{
      headers: {
        locale: lng
      }}).then((response) => {
      setRecipeData(response.data);
      // console.log(response.data.data.recipes);
    });
  }, []);
  // if (!post) return null;
  return (
    <>

        <div className="recipeDetails">
        <div className="details_left">
          <h3 className="title">{lng === 'en' ? recipe.name_en : recipe.name_ar}</h3>
   
          <p className="sec_title">{t("recipe_ingredients")}:</p>
          {lng === 'en' ? renderHTMLContent(recipe.ingredients_en) :  renderHTMLContent(recipe.ingredients_ar)}
          <p className="sec_title">{t("recipe_instruction")}</p>
          {lng === 'en' ? renderHTMLContent(recipe.instruction_en) :  renderHTMLContent(recipe.instruction_ar)}
        </div>
        <div className="details_right">
        <img src={recipe.featured_image} width="100%" />
          <p className="sec_title">{t("recipe_like")}</p>
          <div className="details_rightContainer">
            {recipeData.slice(0, 4).map((recipe) => (
              <Link to={"/" + lng + `/recipe/`+ recipe.id} key={recipe.id}>
                <Card
                  id={recipe.id}
                  name={lng === 'en' ? recipe.name_en : recipe.name_ar}
                  src={recipe.featured_image}
                />
              </Link>
            ))}
          </div>
        </div>
        {/* </div> */}
      </div>
    
    </>
  );
};

export default RecipeDetails;
