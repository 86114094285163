import { Menu } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { NavLink, Link } from "react-router-dom";
import Logo from "../assets/images/logo.svg";

import "../assets/styles/Navbar.css";
import LangSelector from "./LangSelector";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import '../../node_modules/react-modal-video/css/modal-video.css';

export default function Navbar() {
  const { t } = useTranslation();
  const [toggleState, setToggleState] = useState(false);
  const [currentLang, setCurrentLang] = useState(false);
  const [about, setAbout] = useState(false);
  const [video, setVideo] = useState(false);
  useEffect(() => {
    if (i18next.language == "ar") {
      setCurrentLang(true);
    } else {
      setCurrentLang(false);
    }
  }, [t]);
  const toggle = () => {
    setToggleState(toggleState === false ? true : false);
  };
  const locale = i18next.language;
  return (
    <header>
      <div className="logo">
        <Link to="/" className="img_container">
          <img src={Logo} alt="" />
        </Link>
      </div>

      <nav className={`${currentLang && "nav_ar"}`}>
        <Menu className="fa-align-right" onClick={toggle} />
        <ul
          className={`collapsed ${toggleState ? "is-expanded" : ""} ${
            currentLang && "navLink_ar"
          }`}
        >
          {/* <li className="langSelectorM">
            <LangSelector />
            abc
          </li> */}
          
          <li className={`${currentLang ? "nave_home_ar" : "nav_home"}`}>
            <NavLink activeClassName="active" onClick={toggle} to="/">
              {t("nav_home")}
            </NavLink>
          </li>
          <li>
            <NavLink activeClassName="active" to={"/" + locale + "/products"} onClick={toggle}>
              {t("nav_products")}
            </NavLink>
          </li>
          <li>
            <NavLink activeClassName="active" to={"/" + locale + "/recipes"} onClick={toggle}>
              {t("nav_recipes")}
            </NavLink>
          </li>
          <li className="dropdown" onClick={() => setVideo(!video)}>
            {t("nav_video")}
            <img src="/images/slideNext.svg" alt="" />
            {video && (
              <div className="dropdown-menu">
                <NavLink
                  activeClassName="active"
                  to={"/" + locale + "/influencers"}
                  onClick={toggle}
                >
                  <a>{t("nav_influencers")}</a>
                </NavLink>
                <NavLink
                  activeClassName="active"
                  to={"/" + locale + "/cooking-class"}
                  onClick={toggle}
                >
                  <a>{t("nav_cooking_class")}</a>
                </NavLink>
              </div>
            )}
          </li>
          <li className="dropdown" onClick={() => setAbout(!about)}>
            {t("nav_about")}
            <img src="/images/slideNext.svg" alt="" />
            {about && (
              <div className="dropdown-menu">
                <NavLink activeClassName="active" to={"/" + locale + "/about"} onClick={toggle}>
                  <a>{t("nav_about_us")}</a>
                </NavLink>
                <NavLink activeClassName="active" to={"/" + locale + "/ceo"} onClick={toggle}>
                  <a>{t("nav_ceo_message")}</a>
                </NavLink>
                <NavLink
                  activeClassName="active"
                  to={"/" + locale + "/our-history"}
                  onClick={toggle}
                ><a>{t("nav_our_history")}</a>
                </NavLink>
                <NavLink
                  activeClassName="active"
                  to={"/" + locale + "/distributors"}
                  onClick={toggle}
                >
                  <a>{t("nav_distributors")}</a>
                </NavLink>
              </div>
            )}
          </li>
          
            <li><a href="https://careers.alrubaiyat.com/en/page/bfsa/">{t("nav_join")}</a></li>
          
          {/* <li className="langSelectorD"> */}
            <LangSelector />
          {/* </li> */}
        </ul>
      </nav>
    </header>
  );
}
