import ModalVideo from 'react-modal-video'
import {useState, useEffect} from "react";
import { Link } from "react-router-dom";

const Card = ({id,name,src,views,time,video_url,video_modal,link}) => {
  const [isOpen, setOpen] = useState(false);
  return (
    <div className="recipe_slide">
      { video_modal ? (
       <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId={video_url} onClose={() => setOpen(false)} />
      ) : 
      ( <div></div>)
      }
        <img src={src} alt="img" loading="lazy" onClick={()=> setOpen(true)}/>
        <p className="recipe_name">
          {name}
        </p>
      {/* <p className="recipe_time">{views} views • {time} ago</p> */}
    </div>
  );
};

export default Card;
