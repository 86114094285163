import "../assets/styles/Products.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
const Products = ({ src, name }) => {
  const { t } = useTranslation();
  return (
    <Link to={"/" + "en" + `/products/` + name}>
    <div className="products">
   
      <img src={src} alt={name} />
      
    <div className="products_content">
        <h3 className="products_title">{name}</h3>
        <button className="btn"> {t("home_learn_more")}</button>
      </div>
     
    </div>
    </Link>

  );
};

export default Products;
