import Slider from "react-slick";
import "../assets/styles/RecipeSlider.css";
import { useEffect, useRef, useState } from "react";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import { recipeDate } from "../fakeData/recipeDate";
import Card from "./Card";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import axios from "axios";
import React from "react";
import { useParams } from 'react-router-dom';
import { Link } from "react-router-dom";

const RecipeSlider = ({sliders}) => {
  // const { lng } = useParams();
  const { t } = useTranslation();
  const [currentLang, setCurrentLang] = useState(false);
  useEffect(() => {
    if (i18next.language == "ar") {
      setCurrentLang("ar");
    } else {
      setCurrentLang("en");
    }
  }, [t]);
  // console.log("lngs:");console.log(sliders);
  // const baseURL = "https://mazola-api-v2.herokuapp.com/api/v1/recipes";

  // const [jsonData, setData] = React.useState([]);
  // React.useEffect(() => {
  //   axios.get(baseURL,{
  //     headers: {
  //       locale: i18next.language
  //     }}).then((response) => {
  //     setData(response.data.data.recipes);
  //     console.log(response.data.data.recipes);
  //   });
  // }, []);

  const slider = useRef(null);
  const settings = {
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <div className="recipe">
      <p className="recipe_prev" onClick={() => slider?.current?.slickPrev()}>
        <ChevronLeft style={{ fontSize: "45px" }} />
      </p>
      <Slider ref={slider} {...settings}>
      {sliders && (sliders.map((recipe) => (
         <Link to={"/" + 'en' + `/recipe/`+ recipe.id} key={recipe.id}>
            <Card
              id={recipe.id}
              name={currentLang === 'en' ? recipe.name_en : recipe.name_ar}
              src={recipe.featured_image}
            />
          </Link>
          )))}
      </Slider>
      <p className="recipe_next" onClick={() => slider?.current?.slickNext()}>
        <ChevronRight style={{ fontSize: "45px" }} />
      </p>
    </div>
  );
};

export default RecipeSlider;
