export const catalogData = [
    {
        "id": 1,
        "title": "Horeca Catalogue",
        "description": "",
        "image": "https://mazola-prod.s3.me-south-1.amazonaws.com/images/catalouge-1.jpg",
        "url": "https://online.anyflip.com/yszzk/ilha/mobile/index.html",
        "type": "anyflip"
    },
    {
        "id": 2,
        "title": "Retail Catalogue",
        "description": "",
        "image": "https://mazola-prod.s3.me-south-1.amazonaws.com/images/catalogue+2.jpg",
        "url": "https://online.anyflip.com/yszzk/pvyz/mobile/index.html",
        "embed": "anyflip"
    }
];
  