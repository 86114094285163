import Card from "../components/Card";
import HeroSlider from "../components/HeroSlider";
// import { influencersData } from "../fakeData/influencersData";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { API_URL } from '../configs';

const Influencers = () => {
  const { t } = useTranslation();
  const { lng } = useParams();
  const [isOpen, setOpen] = useState(false);
  const [influencers, setInfluencers] = useState([]);
  const baseURL = `${API_URL}/api/v2/influencers?filter={"active":[true]}`;
  // const bannersBaseUrl = "https://mazola-api-v2.herokuapp.com/api/v2/banners";
  useEffect(() => {
    axios
      .get(baseURL, {
        headers: {
          locale: lng,
        },
      })
      .then((response) => {
        setInfluencers(response.data);
      });
  }, []);

  return (
    <>
      
      <div className="recipes">
        <h3 className="title">{t("influencer_title")}</h3>
        <p className="about_secHeading">{t("influencer_description")}</p>
        <div className="recipes_container">
          {influencers.map((influencer) => (
            <Card
              key={influencer.id}
              id={influencer.id}
              name={lng === 'en' ? influencer.name_en : influencer.name_ar}
              src = {influencer.featured_image}
              video_url={influencer.video_url}
              video_modal={true}
            />
          ))}
        </div>

        {/* <button className="btn-primary" onClick={()=> setOpen(true)}>VIEW DEMO</button> */}
      </div>
    </>
  );
};


export default Influencers;
