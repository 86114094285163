import { Link } from "react-router-dom";
import i18next from "i18next";

const ProductCard = ({id, featured_image, name }) => {
  const locale = i18next.language ? i18next.language : "en" ;
  return (
    // <Link to={`/products/${name}`} className="productList_card">
    <Link to={"/" + locale + `/product/`+ id}  className="productList_card">
      <img src={featured_image} alt={name} loading="lazy" className="productList_cardImage" />
      <p className="recipe_name productList_cardName center">{name}</p>
      {/* <p className="recipe_time productList_cardSize">{size}</p> */}
    </Link>
  );
};

export default ProductCard;
