import "../assets/styles/History.css";
import HeroSlider from "../components/HeroSlider";
import React from "react";
import axios from "axios";
import { useParams } from 'react-router-dom';
import { sanitizeHtml } from 'sanitize-html';
import renderHTMLContent from "../components/html";

const History = () => {
  const { lng } = useParams();
  const baseURL = "https://mazola-api-v2.herokuapp.com/api/v1/cms/36";
  const [jsonData, setData] = React.useState([]);
  React.useEffect(() => {
    axios.get(baseURL,{
      headers: {
        locale: lng
      }}).then((response) => {
      setData(response.data.data.cms);
      console.log(response.data.data.cms);
      // console.log(sanitizeHtml(response.data.data.cms.content));
    });
  }, []);
  return (
    <>
      <div className="history">
        <h2 className="title">{jsonData.title}</h2>
          {renderHTMLContent(jsonData.content)}
      </div>
    </>
  );
};

export default History;
