import React, { useState } from "react";
import { useTranslation, initReactI18next } from "react-i18next";
import { useParams } from 'react-router-dom';
import { useEffect } from "react";
const LangSelector = () => {
  const { i18n } = useTranslation();
  // let { locale } = useParams();
  const [selectedLang, setSelectedLang] = useState("en");
  const currentPathname = window.location.pathname.replace(/\/+$/, '');
  console.log("currentPathname:"); console.log(currentPathname);
  var regex = /\/(.*?)\//;
  var lang = regex.exec(currentPathname)?.[1] || "en";
  const locale = lang ;
  // console.log("locale:");console.log(locale);
  const changeLanguage = (lng) => {
    lng = lng ? lng : locale
    // console.log("lng:"); console.log(lng);
    setSelectedLang(lng);
    i18n.changeLanguage(lng);
    document.documentElement.setAttribute('lang', lng);
    // const langURI = window.location.pathname.split('/')[1];
    // console.log(langURI);
    // const routes = i18n.getResourceBundle(i18n.language, 'routes');
    // console.log("route:"); console.log(routes);
    // const currentPathname = window.location.pathname.replace(/\/+$/, '');
    // console.log("currentPathname:"); console.log(currentPathname);
    // var baseurl = window.location.origin;
    // console.log("baseurl:"); console.log(baseurl);

    var url = window.location.toString();
    // console.log("url:");console.log(url);
    // var regex = /\/(.*?)\//;
    // var matched = regex.exec(currentPathname)[1];
    // console.log("matched:");console.log(matched);
    // regex = /(\/[A-z]+\/){1}/
    window.location = url.replace(/(\/[A-z]+\/){1}/, '/' + lng + '/');
  }

  // const changeLanguage = (event) => {
  //   setSelectedLang(event.target.value);
  //   i18n.changeLanguage(event.target.value);
  //   // const newUrl = window.location.pathname.replace('/:locale' , event.target.value);
  //   // window.location.replace(newUrl);
  // };
  // i18n
  // .changeLanguage(locale)
  // .then((t) => {
  //   t('key'); // -> same as i18next.t
  // });
  
  // const changeLanguage = (locale) => {
  //   setSelectedLang(locale);
  //   i18n.changeLanguage(locale);
  //   // const newUrl = window.location.pathname.replace('/:locale' , event.target.value);
  //   // window.location.replace(newUrl);
  // };
  useEffect(() => {
    // console.log("locale:");
    // console.log(locale)
    // i18n.changeLanguage(lng);
  }, []);
  return (
    <>
      <a className="lang_flex">
      <li className="lang_flex_li" onClick={() => changeLanguage('ar')}>العربية</li>
      </a>
      <a className="lang_flex english">
        <li onClick={() => changeLanguage('en')}>English</li>
      </a>
    </>
    // <div onChange={changeLanguage}>
    //   <label className="mr10" htmlFor="lang" />
    //   <select
    //     id="lang"
    //     name="lang"
    //     style={{
    //       border: "none",
    //       background: "#fff",
    //       outline: "none",
    //       fontSize: "18px",
    //       width: "80px",
    //     }}
    //   >
    //     <option value="en">English</option>
    //     <option value="ar">Arabic</option>
    //   </select>
    // </div>
  );
};
export default LangSelector;
