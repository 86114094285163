import Card from "../../components/Card";
import { recipeDate } from "../../fakeData/recipeDate";
import "../../assets/styles/Recipes.css";
import { Link } from "react-router-dom";
import axios from "axios";
import React from "react";
import useFetch from "../../api/api";
import i18next from "i18next";
import { useParams } from "react-router-dom";
import { Url, themeDefault, namesOfModes } from "../../constants/global";
import { useTranslation } from "react-i18next";
import { API_URL } from '../../configs';
// import { lng } from "../../components/lng";

const Recipes = () => {
  const { t } = useTranslation();
  const { lng } = useParams();
  // const locale = i18next.language;
  const baseURL = `${API_URL}/api/v2/recipes?filter={"active":[true]}`;
  // const jsonData = [];
  // const {jsonData} = useFetch('https://mazola-api-v1.herokuapp.com/api/v1/recipes',{locale:"en",node:"recipes"});
  // console.log("data:");

  // console.log(i18next.language);
  // const [data, setdata] = React.useState(null);
  const [jsonData, setData] = React.useState([]);
  const [recipes, setRecipes] = React.useState([]);
  React.useEffect(() => {
    axios
      .get(baseURL, {
        headers: {
          locale: lng,
        },
      })
      .then((response) => {
        setRecipes(response.data)
      });
  }, []);
  // if (!jsonData) return null;

  return (
    <>
      <div className="recipes">
        <h3 className="title">{t("recipe_title")}</h3>
        <p className="about_secHeading">{t("recipe_description")}</p>
        <div className="recipes_container">
          {recipes.map((recipe) => (
            <Link to={"/" + lng + `/recipe/` + recipe.id} key={recipe.id}>
              <Card
                id={recipe.id}
                name={lng === 'en' ? recipe.name_en : recipe.name_ar}
                src={recipe.featured_image}
              />
            </Link>
          ))}
        </div>
      </div>
    </>
  );
};

// const axios = require('axios').default;

// // Make a request for a user with a given ID
// axios.get('https://mazola-api-v1.herokuapp.com/api/v1/recipes')
//   .then(function (response) {
//     // handle success
//     var recipes = response.data.data.recipes;
//     console.log(recipes);
//   })
//   .catch(function (error) {
//     // handle error
//     console.log(error);
//   })
//   .then(function () {
//     // always executed
//   });

export async function getStaticProps() {
  return {
    props: {},
    // Next.js will attempt to re-generate the page:
    // - When a request comes in
    // - At most once every 10 seconds
    revalidate: 5, // In seconds
  };
}

export default Recipes;
