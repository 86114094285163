import HeroSlider from "../components/HeroSlider";
import "../assets/styles/Compare.css";

const Compare = () => {
  return (
    <>
      <HeroSlider
        slider1="images/product01.jpg"
        slider2="images/product04.jpg"
        slider3="images/product05.jpg"
        slider4="images/product06.jpg"
      />
      <div className="compare">
        <h3 className="title">Compare Our Products</h3>
        <div className="compare_container">
          <div className="compare_left">
            <select>
              <option value="Select A Country">Select A Product</option>
              <option value="Mayonnaise Combo">Mayonnaise Combo</option>
              <option value="Classic Mayonnaise">Classic Mayonnaise</option>
            </select>
            <img src="images/product04.jpg" alt="" />
            <p className="about_secHeading">Mayonnaise Combo</p>
            <p className="time">680 ml | 28.95 SAR</p>
            <ul>
              <li className="descp">
                Spicy sauces as well as exquisite dips made of high quality,
                carefully selected ingredients.
              </li>
              <br/>
              <li className="descp">
                The perfect sauces to warm and cold dishes, like salads or grill
                specialties.
              </li>
              <br/>
              <li className="descp">
                Quality food products for the highest demands Range of great
                flavors in both jars and squeezable bottles.
              </li>
              <br/>
              <li className="descp">
                Traditional packed bottles to ensure best quality
              </li>
            </ul>
          </div>
          <div className="compare_line"></div>
          <div className="compare_right">
            <select>
              <option value="Select A Country">Select A Product</option>
              <option value="Mayonnaise Combo">Mayonnaise Combo</option>
              <option value="Classic Mayonnaise">Classic Mayonnaise</option>
            </select>
            <img src="images/product06.jpg" alt="" />
            <p className="about_secHeading">Classic Mayonnaise</p>
            <p className="time">680 ml | 28.95 SAR</p>
            <ul>
              <li className="descp">
                Spicy sauces as well as exquisite dips made of high quality,
                carefully selected ingredients.
              </li>
              <br/>
              <li className="descp">
                The perfect sauces to warm and cold dishes, like salads or grill
                specialties.
              </li>
              <br/>
              <li className="descp">
                Quality food products for the highest demands Range of great
                flavors in both jars and squeezable bottles.
              </li>
              <br/>
              <li className="descp">
                Traditional packed bottles to ensure best quality
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Compare;
