import HeroSlider from "../../components/HeroSlider";
import "../../assets/styles/About.css";
import React from "react";
import axios from "axios";
import { useParams } from 'react-router-dom';
import { sanitizeHtml } from 'sanitize-html';
import renderHTMLContent from "../../components/html";


const About = () => {
  const { lng } = useParams();
  const baseURL = "https://mazola-api-v2.herokuapp.com/api/v1/cms/3";
  const [jsonData, setData] = React.useState([]);
  const html = "<strong>hello world</strong>";
  // console.log(sanitizeHtml(html));
  React.useEffect(() => {
    axios.get(baseURL,{
      headers: {
        locale: lng
      }}).then((response) => {
      setData(response.data.data.cms);
      console.log(response.data.data.cms);
      // console.log(sanitizeHtml(response.data.data.cms.content));
    });
  }, []);
  return (
    <>
      {/* <HeroSlider
        slider1="/images/product01.jpg"
        slider2="/images/product06.jpg"
        slider3="/images/product05.jpg"
      /> */}
      <div className="about justify">
        <h2 className="title">{jsonData.title}</h2>
        {renderHTMLContent(jsonData.content)}
      </div>
    </>
  );
};

export default About;
