import HeroSlider from "../../components/HeroSlider";

const NewsDetails = () => {
  return (
    <>
      <HeroSlider
        slider1="images/product02.jpg"
        slider2="images/product05.jpg"
        slider3="images/product06.jpg"
        slider4="images/product04.jpg"
      />
      <div className="newsDetails">
        <h3 className="title" style={{ minWidth: "100%" }}>
          Sunflower Oil World Output To Reach 60 Million Tons Towards 2050
        </h3>
        <p className="time">16 JAN 2021</p>
        <p className="descp" style={{ minWidth: "100%" }}>
          Sunflower Carrier Oil, better known as Sunflower Seed Oil or more
          simply as Sunflower Oil, is cold pressed from the seeds of the big,
          yellow, cheerful Sunflower botanical, a member of the same family as
          the Calendula, Chrysanthemum, Dahlia, and Gerbera flowers. Several
          ancient societies used various parts of the Sunflower for therapeutic
          and culinary purposes. Its dried leaves were also ingredients in
          recreational substances that were used similarly to the way in which
          tobacco was smoked.
          <br />
          <br />
          While in some cultures of ancient South American origin, the seeds of
          the Sunflower were considered to be a source of food, for other
          cultures, namely that of the Aztec and Inca communities, the flower
          was believed to represent the revered sun and, thus, was worshipped.
          <br />
          <br />
          In Native American Indian practices, the Sunflower’s roots were used
          in medicinal applications, such as for infusions that remedied snake
          bites, and the oil derived from the flower was applied cosmetically as
          a conditioning agent for hair. In Russian folk medicine, the
          Sunflower’s head and leaves were used to address coughs, bronchitis,
          rheumatism, and malaria, among other health conditions.
          <br />
          <br />
          With various body and skin health-enhancing properties and benefits,
          Sunflower Oil continues to be used today in topical applications that
          can be both cosmetic and therapeutic. This article highlights the many
          safe ways in which Sunflower Oil can be applied to restore and advance
          the appearance, texture, and health of skin and hair while promoting
          an overall sense of well-being.
        </p>
      </div>
    </>
  );
};

export default NewsDetails;
