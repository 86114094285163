import HeroSlider from "../../components/HeroSlider";
import chevronRight from "../../assets/images/chevronRight.svg";
import "../../assets/styles/About.css";
import { Link } from "react-router-dom";

const News = () => {
  return (
    <>
       <HeroSlider
      slider1="images/product02.jpg"
      slider2="images/product05.jpg"
      slider3="images/product06.jpg"
      slider4="images/product04.jpg" />
      <div className="about">
        <h2 className="title">Latest News</h2>
        <div className="about_container">
          <div className="about_left">
            <img src="images/product02.jpg" alt="" />
          </div>
          <div className="about_right">
            <p className="news_title">
              Sunflower Oil World Output To Reach 60 Million Tons Towards 2050
            </p>
            <p className="time">16 JAN 2021</p>
            <Link to="/news-details">
              <button className="btn btn_black">
                <img src={chevronRight} alt="news" />
                <span>View Article</span>
              </button>
            </Link>
          </div>
        </div>
        <div className="about_container">
          <div className="about_left">
            <img src="images/product04.jpg" alt="" />
          </div>
          <div className="about_right">
            <p className="news_title">
              Mazola Announces Its New Brand Ambassador During Ramadan
            </p>
            <p className="time">16 JAN 2021</p>
            <Link to="/news-details">
              <button className="btn btn_black">
                <img src={chevronRight} alt="news" />
                <span>View Article</span>
              </button>
            </Link>
          </div>
        </div>
        <div className="about_container">
          <div className="about_left">
            <img src="images/product06.jpg" alt="" />
          </div>
          <div className="about_right">
            <p className="news_title">
              Let This Holiday Season Be High On Fun And Low On Calories
            </p>
            <p className="time">16 JAN 2021</p>
            <Link to="/news-details">
              <button className="btn btn_black">
                <img src={chevronRight} alt="news" />
                <span>View Article</span>
              </button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default News;
