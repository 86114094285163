import { BrowserRouter as Router, Route } from "react-router-dom";
import "./assets/styles/App.css";
import Layout from "./Layout";
import Home from "./pages/Home";
import ProductDetails from "./pages/ProductDetails";
import Products from "./pages/ProductList";
// import Product1 from "./pages/ProductList";
import Recipes from "./pages/Recipe";
import RecipeDetails from "./pages/Recipe/RecipeDetails";
import About from "./pages/About";
import News from "./pages/News";
import NewsDetails from "./pages/News/NewsDetails";
import Cooking from "./pages/Cooking";
import Influencers from "./pages/Influencers";
import Compare from "./pages/Compare";
import Ceo from "./pages/About/Ceo";
import Contact from "./pages/Contact";
import Catalog from "./pages/Catalog";
import History from "./pages/History";
import Distributors from "./pages/Distributors";
import CatalogDetail from "./pages/CatalogDetail";
import i18next from "i18next";
import { useTranslation } from 'react-i18next';

// const locale = i18next.language;
const locale = "/:lng(ar|en)?";
export const baseUrl = i18next.language === 'en' ? '' : '/'+i18next.language;

function App() {
  const { t, i18n } = useTranslation();
  document.body.dir = i18n.dir();
  document.documentElement.lang = i18n.language 
  return (
    <Router>
      <Layout>
        <Route exact path={locale + "/"} component={Home} />
        <Route exact path={locale + "/products"} component={Products} />
        {/* <Route exact path={locale + "/product1"} component={Product1} /> */}
        <Route exact path={locale + "/products/:id"} component={Products} />
        <Route exact path={locale + "/product/:id"} component={ProductDetails} />
        {/* <Route
          exact
          path="/products/product-details"
          component={ProductDetails}
        /> */}
        <Route exact path={locale + "/recipes"} component={Recipes} />
        <Route exact path={locale + "/recipe/:id"} component={RecipeDetails} />
        <Route exact path={locale + "/about"} component={About} />
        <Route exact path={locale + "/ceo"} component={Ceo} />
        <Route exact path={locale + "/news"} component={News} />
        <Route exact path={locale + "/news-details"} component={NewsDetails} />
        <Route exact path={locale + "/cooking-class"} component={Cooking} />
        <Route exact path={locale + "/influencers"} component={Influencers} />
        <Route exact path={locale + "/contact"} component={Contact} />
        <Route exact path={locale + "/our-history"} component={History} />
        <Route exact path={locale + "/compare-products"} component={Compare} />
        <Route exact path={locale + "/catalog"} component={Catalog} />
        <Route exact path={locale + "/distributors"} component={Distributors} />
        <Route exact path={locale + "/catalog/:id"} component={CatalogDetail} />
      </Layout>
    </Router>
  );
}

export default App;
