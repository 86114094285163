import { Add } from "@material-ui/icons";
import ProductCard from "../components/ProductCard";
import { productsData } from "../fakeData/productsData";
import { productsDataAr } from "../fakeData/productsData-ar";
import "../assets/styles/ProductList.css";
import useFetch from "../api/api";
import axios from "axios";
import React from "react";
import { useParams } from "react-router-dom";
import HeroSlider from "../components/HeroSlider";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { useState, useEffect } from "react";
import { API_URL } from '../configs';

const ProductList = () => {
  const { t } = useTranslation();
  const { lng } = useParams();
  const locale = i18next.language == "en" ? "en" : "ar";
  const [currentLang, setCurrentLang] = useState(false);

  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');

    // Fetch all products and categories from the API on component mount
    useEffect(() => {
      const fetchData = async () => {
        const productsResponse = await axios.get(`${API_URL}/api/v2/products?filter={"active":[true]}`);
        setProducts(productsResponse.data);
        const categoriesResponse = await axios.get(`${API_URL}/api/v2/categories`);
        setCategories(categoriesResponse.data);
      }
      fetchData();
    }, []);

  // Handle category filter change
  // const handleCategoryChange = (event) => {
  //   setSelectedCategory(event.target.value);
  // }

  // const [selectedCategory, setSelectedCategory] = useState(null);

  // Filter products by category
  const filteredProducts = products.filter(product => {
    if (!selectedCategory) {
      return true;
    }
    return product.category_id === parseInt(selectedCategory);
  });
    

  return (
    <div className="">
      {/* {banners && <HeroSlider sliders={banners} />} */}
      <div className="productList">
        <div className={`productList_left ${currentLang && "product_ar"}`}>
          <h3 className="title productList_category"> {t("category_title")}</h3>
          <p value=""  onClick={() => setSelectedCategory()} className="productList_categoryName"><Add /> <span>All</span></p>
          {categories.map((category) => (
            <p value={category.id} 
              className="productList_categoryName"
              onClick={() => setSelectedCategory(category.id)}
              key={category.id}
            >
              <Add /> <span>{locale === 'en' ? category.name_en : category.name_ar}</span>
            </p>
          ))}
        </div>
        <div className={`productList_right ${currentLang && "productList_ar"}`}>
          
          <div className="productList_rightContainer">
            {filteredProducts.map((data) => {
              console.log("data:",data)
              // const { id, featured_image, name } = data;
              return (
                <ProductCard
                  key={data.id}
                  id={data.id}
                  featured_image={data.featured_image}
                  name={locale === 'en' ? data.name_en : data.name_ar}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductList;
