import "../assets/styles/Catalog.css";
import { useParams } from "react-router-dom";
import {catalogData} from "../fakeData/catalogData";

const Catalog = () => {
    const { id } = useParams();
    console.log(catalogData.find(o => o.id == id)["url"])
  return (
    <>
      <div className="catalog">
        <h3 className="title">Catalogs</h3>
        <div class="iframe">
            <iframe className="catalog_anyflip" src={catalogData.find(o => o.id == id)["url"]}  seamless="seamless" scrolling="no" frameborder="0" allowtransparency="true" allowfullscreen="true" ></iframe>        
        </div>
      </div>
    </>
  );
};

export default Catalog;
